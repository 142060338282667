export const environment = {
  production: false,
  baseURL: 'https://api-dev-tmtrav.tma-swerp.com',
  TOKEN_KEY: 'at',
  REFRESH_TOKEN_KEY: 'rft',
  USER_ROLE_KEY: 'a',
  REFRESH_TOKEN_EXPIRY_KEY: 'b',
  ACCESS_TOKEN_EXPIRY_KEY: 'c',
  USER_PROFILE_KEY: 'user_profile',
  ADMIN_ROLE_KEY: 'x',
  BASE_API_URL: 'https://api-dev-tmtrav.tma-swerp.com',
  SOCIAL_APP_URL: 'https://tmtrav-dev.tma-swerp.com',
  TRAVEL_ASSIST_URL: 'https://tmtrav-travel-dev.tma-swerp.com/tour',
  BOOKING_URL: 'https://tmtrav-booking-dev.tma-swerp.com',
  ECOMMERCE_FOOD_URL: 'https://tmtrav-food-dev.tma-swerp.com',
  ECOMMERCE_URL: 'https://tmtrav-ecommerce-dev.tma-swerp.com',
  CAR_RENTAL_URL: 'https://tmtrav-car-rental-dev.tma-swerp.com/booking/car',
  FLIGHT_URL: 'https://tmtrav-flight-dev.tma-swerp.com',
  SOCTRIP_CENTER_URL: 'https://tmtrav-business-central-dev.tma-swerp.com/home',
  AUTH_STATUS: 'auth_status',
  BUSINESS_CENTRAL_URL: 'https://tmtrav-business-central-dev.tma-swerp.com',
  MAP_URL: 'https://tmtrav-map-resource-dev.tma-swerp.com',
  MAP_ROUTING_URL: 'https://tmtrav-map-dev.tma-swerp.com',
  GOOGLE_MAP_URL: 'https://www.google.com/maps',
  BANNER_URL: 'https://tmtrav-banner-dev.tma-swerp.com',
  AFFILIATE_URL: 'https://tmtrav-affiliate-dev.tma-swerp.com/',
  languages: ['en', 'vi'],
  SESSION_ID: 'session_id',
  PAGE_TITLE: 'Soctrip',
  FLIGHTS_URL: 'https://tmtrav-flight-dev.tma-swerp.com/booking/flight',
  HELP_CENTER_URL: 'https://help.soctrip.com',
  socialAuthenIds: {
    google: '802332645501-0aiir74m36114t6v974bcrg4jrf8k1do.apps.googleusercontent.com',
    facebook: '2619489051654463',
  },
  HELP_CENTER: 'https://help.soctrip.com/articles/tour-booking-guidelines/',
  CURRENCY_CONVERSATION_DATA: 'currency-conversation-data',
  REVIEW_CONTRACT_PDF:
    'https://api-dev-tmtrav.tma-swerp.com/storage/files/download/vn_20240425T114940935148034_00000000-0000-0000-0000-000000000000_term',
  REVIEW_CONTRACT_PDF_VI:
    'https://api-dev-tmtrav.tma-swerp.com/storage/files/download/vn_20240503T112415545305204_00000000-0000-0000-0000-000000000000_soctripconditionsvi',
  APPROVAL_URL: 'https://approval-tmtrav-dev.tma-swerp.com',
  VOUCHER_ENABLED: true,
  prefix: 'tour',
  CHAT_MODULE: 'TRAVEL',
  UN_AUTH_LANG: 'unauthLang',
  META_CODE: 'G-PQBCBB9BDP',
};
