import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { LatLng, latLngBounds } from 'leaflet';
import { IPlace, LocationService, SoctripMapService } from '@soctrip-common/map';
import { PlaceControllerService, PlaceDetailDTO } from '@assistant/angular-map-location-service';
import { ItineraryDto, PlaceVisitDto } from '@assistant/angular-tour-builder-service';

@Injectable({
  providedIn: 'root',
})
export class ItineraryService implements OnDestroy {
  public placeVisitsSubject = new BehaviorSubject<number>(0);
  private isPlaceSaved = new BehaviorSubject<boolean>(true);
  isPlaceSaved$: Observable<boolean> = this.isPlaceSaved.asObservable();
  public markersSubject = new BehaviorSubject<L.Marker[]>([]);
  public allCoordinates = new BehaviorSubject<L.LatLng[]>([]);
  public markersAction$ = this.markersSubject.asObservable();
  private curMarkers: L.Marker[] = [];
  private randomColor: string;
  private colorList: string[] = [
    '#1570EF',
    '#875BF7',
    '#66C61C',
    '#EE46BC',
    '#0BA5EC',
    '#FDB022',
    '#FF692E',
    '#15B79E',
  ];
  private destroy$ = new Subject();
  private totalPlaceIds: string[] = [];
  public iShowQuickView = false;
  currTourId: string = '';
  defaultId = '00000000-0000-0000-0000-000000000000';
  public isShowMarkersCalled: boolean = false;
  onRouting: boolean = false;
  constructor(
    private mapService: SoctripMapService,
    private placeControllerService: PlaceControllerService,
    private locationService: LocationService
  ) {}

  setPlaceSaved(value: boolean): void {
    this.isPlaceSaved.next(value);
  }

  async addPlaceMarkers(
    placeVisits: PlaceVisitDto[],
    day: number,
    colorMarker?: boolean,
    setView?: boolean,
    singleDay?: boolean,
    tourId?: string
  ): Promise<void> {
    if (tourId) {
      const id = tourId;
      let currentId: string = '';
      currentId = !currentId ? id! : currentId!;
      this.totalPlaceIds = currentId !== id ? [] : this.totalPlaceIds;
    } else if (singleDay) {
      this.totalPlaceIds = [];
    }
    const placeIds = placeVisits?.map((place) => place?.place_id) as string[];
    const setOfPlaceIds = placeIds.filter((id) => id !== this.defaultId && id !== '');
    this.totalPlaceIds = this.totalPlaceIds.concat(setOfPlaceIds);
    if (setOfPlaceIds.length > 0) {
      await this.handleAddMarkerBySetOfPlaceIds(
        setOfPlaceIds,
        day,
        colorMarker ? true : false,
        placeVisits
      );
    }
  }

  async handleAddMarkerBySetOfPlaceIds(
    setOfPlaceIds: string[],
    day: number,
    colorMarker: boolean,
    placeVisits: PlaceVisitDto[] = []
  ): Promise<L.Marker[]> {
    return new Promise<L.Marker[]>((resolve, reject) => {
      this.placeControllerService.getPlaceInfo(setOfPlaceIds).subscribe((data) => {
        if (data.data) {
          if (day !== 0) {
            const color: string = colorMarker ? this.getColorByDay(day) : '#FF0000';
            this.addMarkers(data.data, placeVisits, undefined, color);
          } else {
            const color: string = colorMarker ? this.getColorByDay(day) : '#FF0000';
            this.addMarkers(data.data, placeVisits, undefined, color);
          }
          this.markersSubject.next(this.curMarkers);
          resolve(this.curMarkers);
        }
      });
    });
  }

  getColorByDay(day: number): string {
    switch (day) {
      case 1:
        return '#ff692e';
      case 2:
        return '#66c61c';
      case 3:
        return '#fdb022';
      case 4:
        return '#ee46bc';
      case 5:
        return '#344054';
      case 6:
        return '#875bf7';
      case 7:
        return '#1570ef';
      case 8:
        return '#15b79e';
      case 9:
        return '#164c63';
      case 10:
        return '#a15c07';
      default:
        return '#0ba5ec';
    }
  }
  addMarkers(places: PlaceDetailDTO[], placeVisits: PlaceVisitDto[], day?: number, color?: string) {
    try {
      const latLngs: LatLng[] = [];
      const markIcon = day ? this.mapService.MarkerNumIcon(day, color || '') : undefined;
      let listMarkers: L.Marker[] = [];
      places.forEach((place: PlaceDetailDTO, index) => {
        if (place?.id) {
          listMarkers.push(
            ...this.mapService.addMarkers(
              [place],
              false,
              markIcon ? markIcon : this.mapService.MarkerNumIcon(index + 1, color!)
            )
          );
        } else {
          latLngs.push(
            new LatLng(placeVisits[index].latitude || 0, placeVisits[index].longitude || 0)
          );
        }
      });

      const allMarkers = [...listMarkers, ...this.mapService.addMarkers(latLngs, false, markIcon)];

      this.curMarkers.push(...allMarkers);
    } catch (e) {
      console.error('error add markers', e);
    }
  }

  fitBoundsMarkers(isShowQuickView?: boolean, itineraries?: any) {
    try {
      let map = this.mapService.getMap();
      const markerLatLngs = this.curMarkers.map((marker) => marker?.getLatLng());
      if (markerLatLngs.length === 0) return;
      const bounds = latLngBounds(markerLatLngs);
      if (!bounds) return;

      // Calculate the width of the bounds
      const boundsWidth = bounds.getEast() - bounds.getWest();

      // Calculate 2/3 of the map's width
      const twoThirdsMapWidth = (2 / 3) * map.getSize().x;

      // Set maxZoom based on the comparison
      const maxZoom = boundsWidth > twoThirdsMapWidth ? 11 : 14;

      map?.flyToBounds(bounds, { padding: [100, 100], maxZoom: maxZoom });

      if (isShowQuickView) {
        map?.once('moveend', () => {
          setTimeout(() => {
            const sizeMap = Math.round(map.getSize().x / 2 - map.getSize().x / 3);
            this.mapService.updateMapViewTo(map.getCenter(), 'left', sizeMap);
          }, 100);
        });
      }
    } catch (e) {
      console.error('error fit bound', e);
    }
  }

  highlightMarker(placeId: string, place?: IPlace) {
    this.placeControllerService.getPlaceInfo([placeId]).subscribe((data) => {
      if (data.data && data.data[0]?.id) {
        this.mapService.hightLightMarker(data.data[0], this.mapService.getMap().getZoom(), true);
      } else {
        const marker = this.curMarkers.find((m) => {
          let latLngInfo = m.getLatLng();
          return latLngInfo.lat === place?.latitude && latLngInfo.lng === place?.longitude;
        });
        if (marker) {
          this.mapService.hightLightMarker(marker, this.mapService.getMap().getZoom(), true);
          this.locationService.setLocationData(null);
        }
      }
    });
  }

  clearAllMarkers() {
    this.markersSubject.getValue()?.forEach((marker) => {
      this.mapService.removeMarker(marker);
    });
    this.markersSubject.next([]);
    this.totalPlaceIds = [];
    // Hide marker routing
    this.onShowRouting([]);
  }

  getRandomColor() {
    if (this.colorList.length === 0) {
      this.colorList = [
        '#1570EF',
        '#875BF7',
        '#66C61C',
        '#EE46BC',
        '#0BA5EC',
        '#FDB022',
        '#FF692E',
        '#15B79E',
      ];
    }
    const randomIndex: number = Math.floor(Math.random() * this.colorList.length);
    this.randomColor = this.colorList[randomIndex];
    this.colorList.splice(randomIndex, 1);
    return this.randomColor;
  }

  onShowRouting(
    latLngList: L.LatLng[],
    showMovingMarkers: boolean = false,
    time?: number,
    color?: string
  ) {
    if (this.onRouting) return;
    // this.onRouting = true;
    // let timeout = 0;
    // if (this.mapService.getMap() === undefined) {
    //   timeout = 500; // for this time out I set 500ms if map not init yet, if mao is init before, time out =0. So Please don't remove this line. Thank
    // }

    setTimeout(() => {
      this.mapService.birdDirection(latLngList!, color);
    }, 500);
    // if (showMovingMarkers) {
    //   let curvePolyline;
    //   for (let i = 0; i < latLngList.length - 1; i++) {
    //     setTimeout(() => {
    //       if (curvePolyline) this.mapService.removePolyLine(curvePolyline);
    //       curvePolyline = this.mapService.animationCurvePolyline(
    //         [latLngList[i], latLngList[i + 1]],
    //         time!,
    //         color!
    //       );
    //     }, time! * i);
    //   }
    // }
  }

  getLatLngList(itineraries: ItineraryDto[], showRouting?: boolean, zoom: boolean = false) {
    const latLngList: LatLng[] = [];
    itineraries?.forEach((itinerary) => {
      itinerary.place_visits?.forEach((place) => {
        if (place.latitude && place.longitude) {
          const latLng = new LatLng(place.latitude, place.longitude);
          latLngList.push(latLng);
        }
      });
    });
    if (showRouting) {
      this.onShowRouting(latLngList);
    }
  }
  previousItineraries: any[] = [];

  checkPreviousItineraries(itineraries: any[]): boolean {
    if (JSON.stringify(this.previousItineraries) === JSON.stringify(itineraries)) {
      return true;
    } else {
      this.previousItineraries = itineraries;
      return false;
    }
  }

  async showMarkers(
    itineraries: ItineraryDto[],
    numberedMarkers?: boolean,
    zoom: boolean = false,
    tourId?: string,
    isShowQuickView?: boolean
  ): Promise<void> {
    try {
      if (!this.isShowMarkersCalled) {
        this.isShowMarkersCalled = true;
        if (numberedMarkers) {
          this.removeOlderMarkers();
          setTimeout(async () => {
            if (itineraries?.length === 1) {
              const { place_visits, day_no } = itineraries[0];
              await this.addPlaceMarkers(
                place_visits as PlaceVisitDto[],
                day_no!,
                true,
                zoom,
                true,
                tourId
              );
              this.resetShowMarkersCalled();
              this.fitBoundsMarkers(isShowQuickView, itineraries);
            } else {
              let currentDay = 1;
              const addMarkersForNextDay = async () => {
                const itinerary = itineraries?.find((itinerary) => itinerary.day_no === currentDay);
                if (itinerary) {
                  const { place_visits, day_no } = itinerary;
                  await this.addPlaceMarkers(
                    place_visits as PlaceVisitDto[],
                    day_no!,
                    true,
                    zoom,
                    itineraries?.length === 1,
                    tourId
                  );
                  currentDay++;
                  addMarkersForNextDay();
                  this.resetShowMarkersCalled();
                } else {
                  this.markersSubject.next(this.curMarkers);
                  this.fitBoundsMarkers(isShowQuickView, itineraries);
                  this.resetShowMarkersCalled();
                }
              };
              addMarkersForNextDay();
            }
          }, 500);
        } else {
          this.placeVisitsSubject
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (value: number) => {
              if (value) {
                const placeVisits: PlaceVisitDto[] =
                  value === 0
                    ? (itineraries.flatMap(
                        (itinerary) => itinerary.place_visits
                      ) as PlaceVisitDto[])
                    : (itineraries[value - 1]?.place_visits as PlaceVisitDto[]);
                if (placeVisits) {
                  await this.addPlaceMarkers(
                    placeVisits,
                    value,
                    zoom,
                    undefined,
                    undefined,
                    tourId
                  );
                }
                this.resetShowMarkersCalled();
                this.fitBoundsMarkers(isShowQuickView, itineraries);
              } else {
                this.resetShowMarkersCalled();
              }
            });
          this.markersSubject.next(this.curMarkers);
          this.fitBoundsMarkers(isShowQuickView, itineraries);
        }
      }
    } catch (e) {
      this.resetShowMarkersCalled();
      console.error('error show markers', e);
    }
  }

  private resetShowMarkersCalled() {
    setTimeout(() => {
      this.isShowMarkersCalled = false;
    }, 200);
  }
  removeOlderMarkers() {
    if (this.curMarkers.length > 0) {
      this.curMarkers.forEach((marker) => {
        this.mapService.listMarkers.forEach((mk) => {
          if (
            mk.getLatLng().lat === marker.getLatLng().lat &&
            mk.getLatLng().lng === marker.getLatLng().lng
          ) {
            this.mapService.removeMarker(mk);
          }
        });
      });
    }
    this.curMarkers = [];
    this.markersSubject.next([]);
  }

  ngOnDestroy(): void {
    this.destroy$.next([]);
    this.destroy$.complete();
  }
}
