import { Injectable, Optional, SkipSelf } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, take } from 'rxjs';

import { LANGUAGE, LOCALSTORAGE_ITEMS } from '@shared/enum';
import { EnsureLoadedOnce } from '@shared/utils';
import { environment } from '@env/environment';

export const localeLanguages: Map<string, string> = new Map([
  ['en', 'en-US'],
  ['vi', 'vi-VN'],
]);

@Injectable({
  providedIn: 'root',
})
export class TranslationService extends EnsureLoadedOnce {
  private _language$ = new BehaviorSubject<string>('');
  language$: Observable<string> = this._language$.asObservable();

  private readonly supportedLanguages: string[] = [LANGUAGE.EN, LANGUAGE.VI];
  private readonly defaultLanguage = LANGUAGE.EN;

  initI18n() {
    this._translateService.addLangs(this.supportedLanguages);
    this._translateService.setDefaultLang(this.defaultLanguage);
  }

  constructor(
    @Optional() @SkipSelf() parent: TranslationService,
    private _translateService: TranslateService
  ) {
    super(parent);
    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this._language$.next(event.lang);
      this.setLanguage(event.lang);
    });
  }

  setLanguage(lang: string) {
    const language =
      lang && this.supportedLanguages.includes(lang.trim().toLowerCase())
        ? lang.trim().toLowerCase()
        : this._translateService.getBrowserLang() || this.defaultLanguage;
    localStorage.setItem(LOCALSTORAGE_ITEMS.LANG, language);
    this._translateService.use(language);
  }

  // getCurrentLanguage(): string | null {
  //   return localStorage.getItem(LOCALSTORAGE_ITEMS.LANG);
  // }

  getLocale(): string {
    const language = localStorage.getItem(LOCALSTORAGE_ITEMS.LANG) || this.defaultLanguage;
    return localeLanguages.get(language) || localeLanguages[0].get(language);
  }

  getTranslation(key: string, param?: Object): string {
    return this._translateService.instant(key, param);
  }

  getTranslationAsync(key: string, interpolateParams?: Object): string {
    let _result: string = '';
    this._translateService
      .get(key, interpolateParams)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          _result = data;
        },
        complete: () => {
          return _result;
        },
      });
    return _result;
  }
  getCurrentLang() {
    return this._translateService.currentLang;
  }
}
