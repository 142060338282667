import { HttpClient, HttpClientModule } from '@angular/common/http';
import { importProvidersFrom, ApplicationConfig } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import {
  ApiModule as TourBuilderModuleNpm,
  Configuration as TourBuilderConfigurationNpm,
} from '@assistant/angular-tour-builder-service';
import {
  ApiModule as MapLocationModuleNpm,
  Configuration as MapLocationConfigurationNpm,
} from '@assistant/angular-map-location-service';
import {
  ApiModule as TravelSearchingModuleNpm,
  Configuration as TravelSearchingConfigurationNpm,
} from '@assistant/angular-travel-assistant-searching-service';
import {
  ApiModule as BlogModuleNpm,
  Configuration as BlogConfigurationNpm,
} from '@soctrip/blog-service-api';
import {
  ApiModule as HashtagModuleNpm,
  Configuration as HashtagConfigurationNpm,
} from '@assistant/angular-hashtag-service';
import {
  ApiModule as BookmarksModuleNpm,
  Configuration as BookmarksConfigurationNpm,
} from '@soctrip/angular-bookmarks-service';
import {
  ApiModule as StorageModuleNpm,
  Configuration as StorageConfigurationNpm,
} from '@soctrip/angular-storage-service';
import {
  ApiModule as TourReservationModuleNpm,
  Configuration as TourReservationConfigurationNpm,
} from '@assistant/angular-tour-reservation-service';
import {
  ApiModule as TourOTAModule,
  Configuration as TourOTAConfigurationNpm,
} from '@assistant/angular-tour-ota-service';
import {
  ApiModule as BannerSystemModuleNpm,
  Configuration as BannerSystemConfigurationNpm,
} from '@soctrip/angular-banner-service';
import {
  ApiModule as CarRentalModuleNpm,
  Configuration as CarRentalConfigurationNpm,
} from '@car-rental/angular-car-rental-service';
import {
  ApiModule as ShipmentModuleNpm,
  Configuration as ShipmentConfigurationNpm,
} from '@soctrip/angular-shipment-service';
import {
  ApiModule as EcommerceWalletModuleNpm,
  Configuration as EcommerceWalletConfigurationNpm,
} from '@soctrip/angular-ecommerce-wallet-service';
import {
  ApiModule as EcommerceFinanceModuleNpm,
  Configuration as EcommerceFinanceConfigurationNpm,
} from '@soctrip/angular-ecommerce-finance-service';
import {
  ApiModule as RegionModuleNpm,
  Configuration as RegionConfigurationNpm,
} from '@soctrip/angular-regional-service';
import {
  ApiModule as PaymentModuleNpm,
  Configuration as PaymentConfigurationNpm,
} from '@soctrip/angular-payment-service';

import { APP_ROUTES } from './app.routes';
import { AUTH_INTERCEPTOR_PROVIDER } from './core/interceptors/auth/auth.interceptor';
import { environment } from '../environments/environment';
import { SoctripMapModule } from '@soctrip-common/map';
import { LANGUAGE } from '@shared/enum/language.enum';
import { MessageService } from 'primeng/api';
import { AppSecurityModule } from '@soctrip-common/app-security';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translate/', '.json');
}

export function ApiConfigFactory(path: string) {
  return {
    // set configuration parameters here
    basePath: `${environment.baseURL}/${path}`,
  };
}

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      /* @angular */
      HttpClientModule,
      ReactiveFormsModule,
      // BookingModuleNpm.forRoot(
      //   () => new BookingConfigurationNpm(ApiConfigFactory('booking-property-data'))
      // ),
      StorageModuleNpm.forRoot(() => new StorageConfigurationNpm(ApiConfigFactory('storage'))),
      TourBuilderModuleNpm.forRoot(
        () => new TourBuilderConfigurationNpm(ApiConfigFactory('tour-builder'))
      ),
      MapLocationModuleNpm.forRoot(
        () => new MapLocationConfigurationNpm(ApiConfigFactory('map-location'))
      ),
      TravelSearchingModuleNpm.forRoot(
        () => new TravelSearchingConfigurationNpm(ApiConfigFactory('travel-assistant-searching'))
      ),
      BlogModuleNpm.forRoot(() => new BlogConfigurationNpm(ApiConfigFactory('blog'))),
      HashtagModuleNpm.forRoot(() => new HashtagConfigurationNpm(ApiConfigFactory('hashtag'))),
      TourOTAModule.forRoot(() => new TourOTAConfigurationNpm(ApiConfigFactory('tour-ota'))),
      BookmarksModuleNpm.forRoot(
        () => new BookmarksConfigurationNpm(ApiConfigFactory('bookmarks'))
      ),
      TourReservationModuleNpm.forRoot(
        () => new TourReservationConfigurationNpm(ApiConfigFactory('tour-reservation'))
      ),
      BannerSystemModuleNpm.forRoot(
        () => new BannerSystemConfigurationNpm(ApiConfigFactory('banner'))
      ),
      CarRentalModuleNpm.forRoot(
        () => new CarRentalConfigurationNpm(ApiConfigFactory('car-rental'))
      ),
      SoctripMapModule.setEnvironment(
        environment.MAP_URL,
        environment.MAP_ROUTING_URL,
        environment.BASE_API_URL
      ),
      TranslateModule.forRoot({
        defaultLanguage: LANGUAGE.EN,
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler,
        },
      }),
      ShipmentModuleNpm.forRoot(() => new ShipmentConfigurationNpm(ApiConfigFactory('shipment'))),
      EcommerceWalletModuleNpm.forRoot(
        () => new EcommerceWalletConfigurationNpm(ApiConfigFactory('ecommerce-wallet'))
      ),
      EcommerceFinanceModuleNpm.forRoot(
        () => new EcommerceFinanceConfigurationNpm(ApiConfigFactory('ecommerce-finance'))
      ),
      RegionModuleNpm.forRoot(() => new RegionConfigurationNpm(ApiConfigFactory('regional'))),
      PaymentModuleNpm.forRoot(() => new PaymentConfigurationNpm(ApiConfigFactory('payment'))),
      AppSecurityModule.forRoot(),
    ]),
    provideEnvironmentNgxMask(),
    provideAnimations(),
    provideRouter(APP_ROUTES, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    AUTH_INTERCEPTOR_PROVIDER,
    MessageService,
  ],
};
