import { Injectable } from '@angular/core';
import {
  AgencyRoleDTO,
  TourUserControllerService,
  UserRoleDetailDTO,
} from '@assistant/angular-tour-ota-service';
import { UserService } from './user.service';
import { BehaviorSubject, Observable, filter, map, of, switchMap } from 'rxjs';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { PermissionService } from '../permission/permission.service';

@Injectable({
  providedIn: 'root',
})
export class UserRolesResolverService {
  constructor(
    private tourUserControllerService: TourUserControllerService,
    private userService: UserService,
    private permissionService: PermissionService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = this.userService.getUserInfoFromLocalStorage()?.id;
    return this.tourUserControllerService.getUserRoles(id).pipe(
      map((res) => {
        if (res.success) {
          let agency: AgencyRoleDTO | null = null;
          if (res.success && res.data?.agencies?.length! > 0) {
            agency = res.data?.agencies?.find((item) => state.url.includes(item.agency_id!))!;
            const role = agency?.roles?.find((role) => state.url.includes(role.tour_group_id!));
            this.permissionService.nextPermissions(role?.permission!);
            this.permissionService.nextUserRoleOfTourGrp(role?.role!);
          }
        }
      })
    );
  }
}
