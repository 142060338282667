import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { OverlayModule } from 'primeng/overlay';
import { AvatarService } from '@shared/services/avatar.service';
import { UserProfileService } from '@shared/services/user-profile.service';
import { MenuItem } from 'primeng/api';
import { AGENCY_TOP_BAR_MENU } from 'src/app/config/agency-topbar-menu.config';
import { SlideMenuModule } from 'primeng/slidemenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppRoutes } from 'src/app/config/routes.config';
import { Subject, map, takeUntil } from 'rxjs';
import {
  TourGroupSearchDetailDTO,
  TourUserControllerService,
  UsersCreateDTO,
} from '@assistant/angular-tour-ota-service';
import { GetApiService } from '@shared/services/get-api.service';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from '@env/environment';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { PermissionService } from '@core/services';
import { TourManagementsSidebarService } from '@modules/tour-ota/components/tour-managements/tour-management-sidebar.service';
import { BadgeModule } from 'primeng/badge';
import { HeaderNotificationsComponent } from '@modules/tour-ota/components/header-notifications/header-notifications.component';
import { ChatService } from '@shared/services/chat.service';

@Component({
  selector: 'app-ota-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    /* primeng */
    OverlayModule,
    DialogModule,
    SlideMenuModule,
    OverlayPanelModule,
    TooltipModule,
    BadgeModule,
    /* pipes */
    TranslateAsyncPipe,
    /* components */
    HeaderNotificationsComponent,
  ],
  templateUrl: './ota-layout.component.html',
  styleUrls: ['./ota-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OtaLayoutComponent implements OnInit, OnDestroy {
  overlayMoreVisible = false;
  overlayUserVisible = false;
  visiblePopupLogout = false;
  showMenubar = false;
  menu: Array<MenuItem> = structuredClone(AGENCY_TOP_BAR_MENU).map((item) => item);
  agencyId: string = '';
  showSideMenubar = false;
  isAgencyCenterPage: boolean;
  accountBalanceUrl = '';
  agencySupportUrl = '';
  chatBadgeCount = '0';
  private unsubscribe$ = new Subject<void>();
  pageTitle = environment.PAGE_TITLE;
  isOwner: boolean = false;
  url: string = '';

  notifyVisible: boolean = false;
  notifyMobileVisible: boolean = false;
  badgeCount: string;
  screenWidth: number;
  env = environment;
  constructor(
    private router: Router,
    public avatarService: AvatarService,
    public userProfileService: UserProfileService,
    private route: ActivatedRoute,
    private getApiService: GetApiService,
    private userControllerService: TourUserControllerService,
    private permissionService: PermissionService,
    private tourManagementsSidebarService: TourManagementsSidebarService,
    private chatService: ChatService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const screenWidth = (event.target as Window).innerWidth;
    this.identifyBreakpoint(screenWidth);
  }

  ngOnInit() {
    this.identifyBreakpoint(window.innerWidth);
    this.showMenubar = this.route.snapshot.data['showMenubar'] || false;
    this.showSideMenubar = this.route.snapshot.data['showSideMenubar'] || false;
    this.isAgencyCenterPage = this.route.snapshot.data['isAgencyCenterPage'] || false;
    this.url = this.router.url;
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
      },
    });
    this.route.paramMap
      .pipe(
        takeUntil(this.unsubscribe$),
        map((params: ParamMap) => ({
          agencyId: params.get('agencyId') || '',
        }))
      )
      .subscribe({
        next: (data) => {
          this.agencyId = data.agencyId;
          this.menu = structuredClone(AGENCY_TOP_BAR_MENU).map((item) => ({
            ...item,
            url: `/${environment.prefix}/${AppRoutes.AGENCY}/${this.agencyId}/${item.url}`,
          }));
          if (data.agencyId) {
            this.getApiService
              .searchTourGroup(data.agencyId, '', 0, 10, '-updated_at|LocalDateTime')
              .subscribe((res) => {
                if (res.success) {
                  const listBranch = res.data!.data as Array<TourGroupSearchDetailDTO>;
                  const defaultBranchId = listBranch.find((x) => x.is_default === true);
                  const userGroupId = defaultBranchId?.id!;
                  this.accountBalanceUrl = `/${environment.prefix}/${AppRoutes.AGENCY}/${this.agencyId}/${AppRoutes.BRANCH}/${userGroupId}/${AppRoutes.ACCOUNT_BALANCE}`;
                  this.agencySupportUrl = `/${environment.prefix}/${AppRoutes.AGENCY}/${this.agencyId}/${AppRoutes.BRANCH}/${userGroupId}/${AppRoutes.AGENCY_SUPPORT}`;
                  this.permissionService.userRoleOfTourGrp$.subscribe((res) => {
                    this.isOwner = res === UsersCreateDTO.RoleEnum.OTAOWNER;
                  });
                  this.getChatPendingCount(this.agencyId);
                }
              });
          }
        },
      });
    this.chatService.pendingSessionChanges.subscribe((value) => {
      if (value && this.agencyId) {
        this.getChatPendingCount(this.agencyId);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleCustomSetting() {}

  handleClickViewProfile(): void {
    alert('View Profile');
  }

  displayPopupLogout(): void {
    this.visiblePopupLogout = true;
  }

  closePopupLogout(): void {
    this.visiblePopupLogout = false;
  }

  handleSignOut(): void {
    this.visiblePopupLogout = false;
    alert('Sign Out');
  }

  handleClickLogo(): void {
    this.router.navigate(['/']);
  }

  handleToggleSidebar(): void {
    this.tourManagementsSidebarService.toggleShowMenubar();
  }

  updateBadge(count: string) {
    this.badgeCount = count;
  }

  identifyBreakpoint(screenWidth: number) {
    this.screenWidth = screenWidth;
  }

  toggleDialog() {
    if (this.screenWidth >= 768) {
      this.notifyVisible = !this.notifyVisible;
    } else {
      this.notifyMobileVisible = !this.notifyMobileVisible;
    }
  }

  getChatPendingCount(agencyId: string) {
    this.getApiService.getAgencyChatSessionsCount(agencyId, 'PENDING', 'TRAVEL').subscribe({
      next: (res: any) => {
        this.chatBadgeCount = String(res?.data);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
