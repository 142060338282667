<div id="t-gallery" [ngClass]="getTypeClass()">
  <p-galleria
    #gallery
    [(activeIndex)]="activeIndex"
    [value]="images"
    [numVisible]="getNumVisible()"
    [containerStyle]="containerStyle"
    [circular]="loop"
    [autoPlay]="autoPlay"
    [transitionInterval]="3000"
    (keyup.arrowLeft)="prev()"
    (keyup.arrowRight)="next()"
  >
    <ng-template pTemplate="item" let-item>
      <div class="t-thumbnail-image">
        <ng-container [ngSwitch]="checkMediaType(item)">
          <ng-container *ngSwitchCase="'video'">
            <video class="w-full h-full" [src]="item" controls></video>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <img [src]="item" />
          </ng-container>
        </ng-container>
        <p-button
          *ngIf="!isPreviewMode && favoriteButton"
          [icon]="isBookmarked ? 'sctr-icon-solid-bookmark' : 'sctr-icon-bookmark'"
          class="t-favorite-button"
          (click)="handleAddToFavorite.emit($event)"
        ></p-button>
        <div class="t-badges-container">
          <div *ngIf="durationBadge" class="t-badge">
            <i class="t-badge-icon sctr-icon-clock"></i>
            <span>
              {{
                getTranslationAsync('pluralization.total_duration', {
                  count: durationBadge,
                  duration: durationUnit.split('(')[0] || ''
                })
              }}
            </span>
          </div>
          <div *ngIf="!isPreviewMode && ratingBadge" class="t-badge">
            <i class="t-badge-icon t-badge-icon-yellow sctr-icon-stars-01"></i>
            <span>{{ getRatingBadgeString(ratingBadge) }}</span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
      <ng-container [ngSwitch]="checkMediaType(item)">
        <ng-container *ngSwitchCase="'video'">
          <app-media-player
            [url]="item"
            [isCover]="true"
            [showToolbar]="false"
            [isPreviewMode]="true"
          ></app-media-player>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <img [src]="item" />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="images.length <= numVisible">
        <button class="t-icon-button left-0" (click)="$event.stopPropagation(); prev()">
          <i class="sctr-icon-chevron-left"></i>
        </button>
        <button class="t-icon-button right-0" (click)="$event.stopPropagation(); next()">
          <i class="sctr-icon-chevron-right"></i>
        </button>
      </ng-container>
    </ng-template>
    <ng-template *ngIf="type === 'custom-1'" pTemplate="previousthumbnailicon">
      <i class="sctr-icon-chevron-left t-thumbnail-icon"></i>
    </ng-template>
    <ng-template *ngIf="type === 'custom-1'" pTemplate="nextthumbnailicon">
      <i class="sctr-icon-chevron-right t-thumbnail-icon"></i>
    </ng-template>
  </p-galleria>
</div>
