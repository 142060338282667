<div
  class="t-ota-layout-section shadow-header w-full h-[4.25rem] bg-white border-b border-gray-200 sticky top-0 z-[999]"
>
  <div
    class="flex justify-between items-center py-3.5 px-6 font-inter"
    [ngClass]="showSideMenubar ? 'px-6' : isAgencyCenterPage ? 'md:w-[75rem] md:mx-auto md:px-0' : 'md:w-[65rem] md:mx-auto md:px-0' "
  >
    <div class="flex gap-2">
      <div *ngIf="showMenubar" class="flex items-center md:hidden">
        <span class="inline px-2 py-0.5 rounded-md cursor-pointer" (click)="handleToggleSidebar()">
          <i class="text-lg pi pi-bars md:text-2xl bg-white text-gray-600"></i>
        </span>
      </div>
      <button aria-label="Home" (click)="handleClickLogo()">
        <img
          src="assets/svg/{{ pageTitle.toLowerCase() }}_logo.svg"
          alt=""
          class="min-w-[7.5rem] h-8"
        />
      </button>
      <div class="hidden text-xl font-medium leading-8 text-gray-700 lg:flex">Travel Tour</div>
    </div>

    <div class="flex gap-6">
      <!-- MENUBAR -->
      <!-- <div *ngIf="showMenubar" class="hidden md:flex">
        <div *ngFor="let item of menu" class="flex items-center">
          <a
            routerLink="{{ item.url }}"
            class="px-3 py-2 font-medium text-gray-500 rounded-md text-md hover:bg-gray-50"
            routerLinkActive="text-primary-600 font-semibold"
          >
            <span>{{ item.label }}</span>
          </a>
        </div>
      </div> -->

      <div class="flex items-center gap-2">
        <a
          aria-label="wallet"
          *ngIf="isOwner"
          [routerLink]="accountBalanceUrl"
          class="px-2 py-2 font-medium text-gray-500 rounded-md text-md {{
            !url.endsWith('account-balance') ? 'hover:bg-gray-200' : 'hover:bg-blue-100'
          }}"
          routerLinkActive="text-primary-600 font-semibold"
          [pTooltip]="'account-balance.title' | translateAsync"
          tooltipPosition="bottom"
          tooltipStyleClass="t-tooltip-light"
          [ngClass]="{ 'bg-blue-50': url.endsWith('account-balance') }"
        >
          <i
            class="{{
              url.endsWith('account-balance') ? 'text-blue-700' : 'text-gray-500'
            }} text-2xl/5 sctr-icon-wallet-02"
          ></i>
        </a>
        <a
          aria-label="agency-support"
          *ngIf="env.SOCCHAT_VISIBLE && agencyId"
          [routerLink]="agencySupportUrl"
          class="px-2 py-2 font-medium text-gray-500 rounded-md text-md flex {{
            !url.endsWith('agency-support') ? 'hover:bg-gray-200' : 'hover:bg-blue-100'
          }}"
          routerLinkActive="text-primary-600 font-semibold"
          [pTooltip]="'agency-support.title' | translateAsync"
          tooltipPosition="bottom"
          tooltipStyleClass="t-tooltip-light"
          [ngClass]="{ 'bg-blue-50': url.endsWith('agency-support') }"
        >
          <i
            pBadge
            [value]="chatBadgeCount"
            severity="danger"
            class="t-bell-noti-header sctr-icon-message-chat-square cursor-pointer text-2xl/none {{
              url.endsWith('agency-support') ? 'text-blue-700' : 'text-gray-500'
            }}"
          ></i>
        </a>
        <!-- TODO: Hide Notification button -->
        <!-- <button class="relative px-2 py-1 rounded hover:bg-gray-200">
          <i class="text-2xl text-gray-500 sctr-icon-bell-01"></i>
          <div
            class="flex justify-center items-center overflow-hidden w-5 h-5 absolute top-px right-0.5 bg-white"
          >
            <div
              class="flex items-center justify-center bg-orange-dark-600 text-white text-[0.5rem] font-bold leading-3 rounded-full w-4 h-4"
            >
              4
            </div>
          </div>
        </button> -->

        <div class="relative">
          <button
            class="flex h-[40px] w-[40px] items-center justify-center rounded hover:bg-gray-200"
            (click)="toggleDialog()"
          >
            <i
              pBadge
              [value]="badgeCount"
              severity="danger"
              class="t-bell-noti-header sctr-icon-bell-01 cursor-pointer text-2xl/none text-gray-500"
            ></i>
          </button>
          <app-header-notifications
            [overlayVisible]="notifyVisible"
            [mobileOverlayVisible]="notifyMobileVisible"
            (updateBadgeCount)="updateBadge($event)"
            (mobileOverlayVisibleChange)="notifyMobileVisible = $event"
          ></app-header-notifications>
        </div>

        <div class="flex items-center gap-3 cursor-pointer" (click)="toggleCustomSetting()">
          <div
            class="flex-col hidden md:flex"
            *ngIf="userProfileService.getData() | async as userProfile"
          >
            <div class="font-semibold leading-5 text-gray-700 text-md">
              {{ userProfile?.full_name }}
            </div>
            <div class="h-4 text-sm font-medium leading-4 text-gray-500">
              <label>{{ userProfile?.email }}</label>
            </div>
          </div>
          <div
            class="relative flex items-center justify-center w-10 h-10 p-1 rounded-full box-avatar"
          >
            <img alt="" class="rounded-full" [src]="avatarService.getData() | async" />
            <!-- Modal when click user avatar -->
            <p-overlay
              [(visible)]="overlayUserVisible"
              [contentStyleClass]="'shadow-2 border-round border-gray-700 border-1'"
              class="absolute top-2 -left-[13.75rem] md:-left-[13.75rem] rounded-lg"
              [responsive]="{ direction: 'bottom-end', contentStyleClass: 'w-30rem' }"
            >
              <div class="overflow-hidden bg-white rounded-lg">
                <div
                  class="flex flex-col gap-3 px-3 py-3 w-[16.25rem] bg-base-white border border-gray-200 cursor-auto rounded-t-lg"
                >
                  <button
                    class="flex items-center justify-center w-full text-base font-semibold text-blue-700 rounded-md bg-blue-50 hover:bg-blue-100 h-11"
                    (click)="handleClickViewProfile()"
                  >
                    <span>{{ 'OTA.HEADER.VIEW_PROFILE' | translateAsync }}</span>
                  </button>
                </div>
                <button
                  class="w-full px-4 py-1 border border-gray-200 rounded-b-lg hover:bg-gray-200"
                  (click)="displayPopupLogout()"
                >
                  <i class="mr-2 text-xl text-red-700" [ngClass]="'sctr-icon-log-out-01'"></i>
                  <span class="font-medium text-gray-700">{{
                    'OTA.HEADER.SIGN_OUT' | translateAsync
                  }}</span>
                </button>
              </div>
            </p-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Popup Confirm Sign Out -->
<p-dialog
  header="{{ 'OTA.HEADER.SIGN_OUT_ACCOUNT' | translateAsync }}"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visiblePopupLogout"
  [style]="{ width: '400px' }"
  [styleClass]="'border-0'"
>
  <div
    class="flex justify-between gap-3 px-4 pt-2 pb-4 text-base font-semibold bg-white rounded-b-lg"
  >
    <button
      class="p-button-text w-[100%] border px-4 border-blue-700 text-gray-700 py-2.5 justify-center cursor-pointer rounded-lg flex hover:opacity-90"
      (click)="closePopupLogout()"
    >
      {{ 'OTA.HEADER.CANCEL' | translateAsync }}
    </button>
    <button
      class="p-button-text w-[100%] border px-4 border-blue-700 text-white bg-blue-700 py-2.5 justify-center cursor-pointer rounded-lg flex hover:opacity-90"
      (click)="handleSignOut()"
    >
      {{ 'OTA.HEADER.SIGN_OUT' | translateAsync }}
    </button>
  </div>
</p-dialog>

<div class="bg-gray-50 min-h-[calc(100vh-4.25rem)]">
  <div [ngClass]="{ 'py-6 w-[75rem] max-w-[calc(100%_-_2_*_1rem)] mx-auto': !showSideMenubar }">
    <router-outlet></router-outlet>
  </div>
</div>
