import {
  AgencyRoleDTO,
  TourUserControllerService,
  UsersCreateDTO,
} from '@assistant/angular-tour-ota-service';
import { UserProfileService } from '@shared/services/user-profile.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AgencyControllerService } from '@assistant/angular-tour-ota-service';
import {
  Observable,
  catchError,
  combineLatest,
  defaultIfEmpty,
  filter,
  finalize,
  interval,
  map,
  of,
  switchMap,
  takeWhile,
  tap,
  throwError,
} from 'rxjs';
import { AppRoutes } from 'src/app/config/routes.config';
import { ToastService, TranslationService } from '@core/services';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AutoNavigateGuard implements CanActivate {
  constructor(
    private router: Router,
    private tourUserControllerService: TourUserControllerService,
    private userProfileService: UserProfileService,
    private agencyControllerService: AgencyControllerService,
    private toastService: ToastService,
    private translationService: TranslationService
  ) {}

  otaOwnerOnlyUrl: string | undefined;
  page;
  loader = false;
  private timeout = 0;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const isByPassGuard = this.router.getCurrentNavigation()?.extras?.state?.['byPassGuard'];
    if (isByPassGuard) return of(true);
    this.loader = true;
    this.page = route.queryParams['page'];
    let isStopWaiting = false;
    // Pauses execution until user profile information is available.
    return interval(100) // Check every 100 milliseconds
      .pipe(
        takeWhile(() => !isStopWaiting && this.timeout < 10000), // Stop checking after 8 seconds or already has user profile
        tap(() => (this.timeout += 100)), // Increment timeout counter
        filter(() => !!localStorage.getItem(environment.USER_PROFILE_KEY)), // Check if user profile exists
        switchMap(() => {
          isStopWaiting = true;
          return this.handleNavigation();
        }),
        catchError(() => {
          // Handle errors (optional)
          this.toastService.error(
            this.translationService.getTranslationAsync(
              'common.component.auth_can_activate.comfirm_msg'
            )
          );
          return throwError(() => new Error('Timeout reached'));
        }),
        finalize(() => {
          isStopWaiting = true;
          this.loader = false;
          if (!localStorage.getItem(environment.USER_PROFILE_KEY)) {
            this.toastService.error(
              this.translationService.getTranslationAsync(
                'common.component.auth_can_activate.comfirm_msg'
              )
            );
          }
        }),
        defaultIfEmpty(this.router.createUrlTree(['/'])) // If no value emitted, redirect to home
      );
  }
  handleNavigation(): Observable<boolean> {
    const isDirectToOwnTour =
      this.router.getCurrentNavigation()?.extras?.state?.['isDirectToOwnTour'];
    const setOtaOwnerOnlyUrl = (agencyId, groupId: string | undefined) => {
      if (!agencyId || !groupId) return;
      this.otaOwnerOnlyUrl = `/${environment.prefix}/${AppRoutes.AGENCY}/${agencyId}/${
        AppRoutes.BRANCH
      }/${groupId}/${
        this.page === AppRoutes.CONTRACTS ? AppRoutes.CONTRACTS : AppRoutes.TOUR_MANAGE
      }`;
    };

    const handleUserRoles = (agencyId, agenciesData: AgencyRoleDTO[]) => {
      let isOtaOwner = false;
      let isOtaMember = false;

      agenciesData.forEach((agency) => {
        if (agency.roles && agency.roles?.length > 0) {
          agency.roles.forEach((rItem) => {
            if (rItem.role === UsersCreateDTO.RoleEnum.OTAOWNER) {
              isOtaOwner = true;
              setOtaOwnerOnlyUrl(agencyId, rItem.tour_group_id);
            } else {
              isOtaMember = true;
            }
          });
        }
      });

      const isOtaOwnerOnly = isOtaOwner && !isOtaMember;
      return isOtaOwnerOnly;
    };

    return this.userProfileService.getData().pipe(
      switchMap((userData) => {
        if (!userData) {
          this.router.navigate(['/']);
          this.toastService.error(
            this.translationService.getTranslationAsync(
              'common.component.auth_can_activate.comfirm_msg'
            )
          );
          return of(false);
        }

        return combineLatest({
          userRoles: this.tourUserControllerService.getUserRoles(userData.id),
          userAgency: this.agencyControllerService.getAgencyIdRegistered(userData.id),
        }).pipe(
          map(({ userRoles, userAgency }) => {
            const userRoleAgencies = userRoles?.data?.agencies;
            const agencyId = userAgency?.data?.agency_id;
            this.loader = false;
            if (userRoleAgencies && userRoleAgencies.length > 0) {
              if (!agencyId) {
                this.router.navigate([`/${environment.prefix}/${AppRoutes.AGENCY_CENTER}`]);
                return false;
              }

              const isOtaOwnerOnly = handleUserRoles(agencyId, userRoleAgencies);
              (isDirectToOwnTour || isOtaOwnerOnly) && this.otaOwnerOnlyUrl
                ? this.router.navigate([this.otaOwnerOnlyUrl])
                : this.router.navigate([`/${environment.prefix}/${AppRoutes.AGENCY_CENTER}`]);
              return true;
            } else {
              this.router.navigate([`/${environment.prefix}/${AppRoutes.REQUEST_BECOME_AGENCY}`], {
                state: { byPassGuard: true },
              });
              return false;
            }
          })
        );
      })
    );
  }
}
