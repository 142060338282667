<div [ngClass]="[offHeader ? 'hidden' : 'bg-[#00000052]']">
  <lib-header
    [(isSearching)]="isSearching"
    [currentPage]="'booking'"
    [environment]="environment"
    [myBookingUrl]="myBookingUrl"
    [userInfo]="userInfo$ | async"
    [(megaVisible)]="megaVisible"
    [visibleShopingCart]="false"
    [visibleSearch]="false"
    [currentTheme]="'darkTheme'"
    [loginReturnUrl]="environment.TRAVEL_ASSIST_URL"
    (updateLanguage)="updateLanguage($event)"
    class="text-gray-900"
  >
    <ng-container customConfig *ngIf="isLogged">
      <a
        [routerLink]="[routes.MY_TOUR]"
        (click)="megaVisible = false"
        class="grid grid-cols-2 justify-items-stretch py-4 px-4 gap-3 w-full cursor-pointer hover:bg-gray-100 border-t-4 border-gray-100"
      >
        <div class="flex items-center gap-3">
          <i class="sctr-icon-flag-05 text-[24px] text-gray-700"></i>
          <span class="w-full text-base font-medium text-gray-700">
            {{ 'page.master_header.my_tours' | translateAsync }}
          </span>
        </div>

        <div class="flex items-center gap-3 justify-end">
          <i class="sctr-icon-chevron-right"></i>
        </div>
      </a>
      <a
        [routerLink]="[routes.MY_PLACE]"
        (click)="megaVisible = false"
        class="grid grid-cols-2 justify-items-stretch py-4 px-4 gap-3 w-full cursor-pointer hover:bg-gray-100"
      >
        <div class="flex items-center gap-3">
          <i class="sctr-icon-marker-pin-01 text-[24px] text-gray-700"></i>

          <span class="w-full text-base font-medium text-gray-700">
            {{ 'page.master_header.my_places' | translateAsync }}
          </span>
        </div>

        <div class="flex items-center gap-3 justify-end">
          <i class="sctr-icon-chevron-right"></i>
        </div>
      </a>
    </ng-container>
  </lib-header>
</div>
