import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { ConfirmationService, Message, PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Toast, ToastModule } from 'primeng/toast';

import { FooterComponent } from '@layout/footer/footer.component';
import { HeaderComponent } from '@layout/header/header.component';
import { ConfirmationComponent } from '@shared/components/confirmation/confirmation.component';
import { BookmarkModalComponent } from '@shared/components/bookmark-modal/bookmark-modal.component';
import { ToastModalBookmarkComponent } from '@shared/components/bookmark-modal/toast-modal-bookmark/toast-modal-bookmark.component';
import { PopupCtaComponent } from '@shared/components/popup-cta/popup-cta.component';

import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service';
import { UserService } from '@core/services/user/user.service';
import { TranslationService } from '@core/services';
import { PermissionService } from '@core/services/permission/permission.service';
import { CtaSigninService } from '@shared/services/cta-signin.service';
import { UserProfileService } from '@shared/services/user-profile.service';
import { GetBloomBookmarkService } from '@shared/services/get-bloom-bookmark.service';
import { GetBloomHelpfulService } from '@shared/services/get-bloom-helpful.service';
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service';
import { AvatarService } from '@shared/services/avatar.service';
import { Location as LocationAngular } from '@angular/common';
import { TOAST_SEVERITY } from '@shared/enum';
import { Breakpoint } from '@shared/enum/breakpoint-enum';
import { HostViewDirective } from '@core/directives/host-view.directive';
import { environment } from '@env/environment';
import { LanguagePreferenceService } from '@shared/services/language-preference.service';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { AutoNavigateGuard } from '@core/guards/auto-navigate/auto-navigate.guard';
import { ProgressBarModule } from 'primeng/progressbar';
import { ChatPopupComponent, SoctripChatModule } from '@soctrip-common/chat';
import { GoogleAnalyticsService } from '@core/services/google-analytics.service ';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    ButtonModule,
    RouterOutlet,
    FooterComponent,
    HeaderComponent,
    ConfirmDialogModule,
    ConfirmationComponent,
    ToastModule,
    TranslateModule,
    CommonModule,
    BookmarkModalComponent,
    ToastModalBookmarkComponent,
    HostViewDirective,
    PopupCtaComponent,
    ProgressBarModule,
    SoctripChatModule,
  ],
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  providers: [ConfirmationService, AvatarService, CurrencyConversionPipe],
})
export class AppComponent implements OnInit, OnDestroy {
  loginForm = {
    phone_or_email: 'team-travel@mailto.plus',
    password: '12345678Aa@',
    area_code: '',
    device: '',
  };
  userRole: string[] = [];
  appConnect = '';
  AppConnectUrl = '';
  socialApp: string = environment.SOCIAL_APP_URL;
  socialAppConnectUrl: string = environment.SOCIAL_APP_URL + '/app-connect';
  socialAppSafeUrl: SafeResourceUrl;
  sanitizer: DomSanitizer;
  TOAST_SEVERITY = TOAST_SEVERITY;
  screenWidth: number;
  BREAKPOINT = Breakpoint;
  isLoading = false;
  approvalPage = false;
  environment = environment;
  userProfile: any = '';
  isEnableChat = true;
  isShowSocchat = false;
  chatBotQuestionField: string;
  unAuthLang = localStorage.getItem(environment.UN_AUTH_LANG) as string;
  @ViewChild('toast') toast: Toast;
  @ViewChild('soctripChatModule') soctripChatModule: ChatPopupComponent;
  destroy$ = new Subject<void>();

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private authService: AuthService,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private permissionService: PermissionService,
    private avatarService: AvatarService,
    private translateService: TranslateService,
    private primeNGConfig: PrimeNGConfig,
    private userProfileService: UserProfileService,
    private getBloomBookmarkService: GetBloomBookmarkService,
    private getBloomHelpfulService: GetBloomHelpfulService,
    private ctaSigninService: CtaSigninService,
    private translationService: TranslationService,
    private languagePreferenceService: LanguagePreferenceService,
    private location: LocationAngular,
    public autoNavigateGuard: AutoNavigateGuard,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.googleAnalyticsService.initialize();
    if (this.location.path().includes('approval=true')) {
      this.approvalPage = true;
      this.appConnect = environment.APPROVAL_URL;
      this.AppConnectUrl = environment.APPROVAL_URL + '/app-connect';
    } else {
      this.appConnect = environment.SOCIAL_APP_URL;
      this.AppConnectUrl = environment.SOCIAL_APP_URL + '/app-connect';
    }
    this.connectPMServer();

    const profile = JSON.parse(localStorage.getItem(environment.USER_PROFILE_KEY)!);
    this.setProfileData(profile);
    this.setAvatar(profile?.avatar_thumbnail_url);
    this.ctaSigninService.setCTAByAccessToken();
    this.translationService.initI18n();
    if (profile) {
      this.translationService.setLanguage(profile?.language as string);
      this.languagePreferenceService.retrieveLanguagePreference(profile.id);
    } else {
      this.translationService.setLanguage(this.unAuthLang as string);
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.translateService.onLangChange
      .pipe(
        switchMap((event: LangChangeEvent) => {
          const res = this.translateService.getParsedResult(event.translations, 'primeng');
          return of(res);
        })
      )
      .subscribe((res: any) => {
        const translations = this.applyPrimeNGTranslations(res);
        this.primeNGConfig.setTranslation(translations);
      });
    this.isEnableChat = this.location.path()?.includes('agency') ? false : true;
  }

  private applyPrimeNGTranslations(translations: any) {
    switch (typeof translations) {
      case 'function':
        return translations();
      case 'object':
        if (Array.isArray(translations)) {
          return translations.map((t) => this.applyPrimeNGTranslations(t));
        }

        for (const key in translations) {
          if (key) {
            translations[key] = this.applyPrimeNGTranslations(translations[key]);
          }
        }
        return translations;
      default:
        return translations;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.screenWidth = (event.target as Window).innerWidth ?? this.screenWidth;
  }

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.AppConnectUrl;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'connect' }, '*');
    };
    ifr.onload = connect;
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    if (
      ['authorized', 'unauthorized'].includes(event.data.type) &&
      event.origin === this.appConnect
    ) {
      this.AppConnectUrl = '';
      const currenProfile = localStorage.getItem(environment.USER_PROFILE_KEY);
      const profile = JSON.parse(event.data.profile ?? '{}');
      if (event.data.type === 'unauthorized' || !event.data || !event.data.token) {
        this.translationService.setLanguage(event.data?.language as string);
        localStorage.setItem(environment.UN_AUTH_LANG, event.data?.language);
        if (!this.approvalPage) {
          this.tokenLogout();
          if (currenProfile || (this.unAuthLang && this.unAuthLang !== event.data.language)) {
            location.reload();
          }
        }
      } else {
        this.tokenStorageService.saveToken(event.data.token.accessToken);
        localStorage.removeItem(environment.UN_AUTH_LANG);
        // if (!(currenProfile && JSON.parse(currenProfile).id === profile.id)) {
        this.userService.getUserInfo(profile.id).subscribe((res: any) => {
          if (res?.data) {
            this.setProfileData(res.data);
            localStorage.setItem(environment.USER_PROFILE_KEY, JSON.stringify(res.data));
            this.userProfile = res.data || '';
            this.translationService.setLanguage(res.data?.language as string);
            if (
              !(
                currenProfile &&
                String(JSON.parse(currenProfile).language).toLocaleLowerCase() ===
                  String(res.data?.language).toLocaleLowerCase()
              ) &&
              !this.autoNavigateGuard.loader
            ) {
              location.reload();
            }
          } else {
            this.tokenLogout();
          }
        });

        this.getBloomBookmarkService.getBloomlFilterString();
        this.getBloomHelpfulService.getBloomlFilterString();
        localStorage.setItem(environment.AUTH_STATUS, JSON.stringify({ isLoggedIn: true }));
        // localStorage.removeItem(environment.USER_PROFILE_KEY);
        // localStorage.removeItem(environment.AUTH_STATUS);
        // localStorage.setItem(environment.USER_PROFILE_KEY, event.data.profile);

        // if (!(currenProfile && JSON.parse(currenProfile).language === profile.language)) {
        //   location.reload();
        // }
        // this.setProfileData(profile);
      }
      this.setAvatar(profile?.avatar_thumbnail_url);
      this.ctaSigninService.setCTAByAccessToken();
    }
  }

  tokenLogout() {
    this.setProfileData(null);
    localStorage.removeItem(environment.USER_PROFILE_KEY);
    localStorage.removeItem(environment.AUTH_STATUS);
    this.tokenStorageService.clearTokenLocalStorage();
    localStorage.setItem(environment.AUTH_STATUS, JSON.stringify({ isLoggedIn: false }));
  }

  setAvatar(avatarThumbnail: string) {
    let avatarUrl = environment.BASE_API_URL;
    if (avatarThumbnail && !avatarThumbnail?.includes('/')) {
      avatarUrl += `/storage/files/web/${avatarThumbnail}`;
    } else {
      avatarUrl = '/assets/images/default-user-avatar.webp';
    }
    this.avatarService.setData(avatarUrl);
  }

  setProfileData(profile: any) {
    this.userProfileService.setData(profile);
  }

  setTheme(theme: string) {
    document.documentElement.className = theme;
  }

  login() {
    this.authService.login(this.loginForm).subscribe({
      next: (res: any) => {
        this.tokenStorageService.saveToken(res.data.access_token);
        this.tokenStorageService.saveRefreshToken(res.data.refresh_token);
        this.tokenStorageService.saveAccessTokenExpiry(res.data.access_token_expiry_date);
        this.tokenStorageService.saveRefreshTokenExpiry(res.data.refresh_token_expiry_date);
        this.userService.getUserInfo(res.data.user.id).subscribe((value: any) => {
          this.userRole = value.data.roles;
          this.userService.saveUserInfoToLocalStorage(this.userRole);
          this.permissionService.userInfo.next(value.data);
        });
        this.permissionService.userRoles.next(this.userRole);
      },
    });
  }

  logout() {
    this.tokenStorageService.clearTokenLocalStorage();
    this.userService.clearUserLocalStorage();
    this.permissionService.userRoles.next(['']);
  }

  onClose() {
    this.confirmationDialogService.clearConfirmationData();
  }

  handleToastItemClick(message: Message) {
    if (message.data?.clickCb) {
      let index = this.toast.messages.findIndex((item) => item === message);
      message.data?.clickCb();
      if (index !== -1) {
        this.toast.messages.splice(index, 1);
      }
    }
  }

  @HostListener('window:storage', ['$event'])
  storageEventListener(event: StorageEvent) {
    if (event.key === environment.USER_PROFILE_KEY) {
      const newValueStorage = event.newValue;
      const newProfile = newValueStorage ? JSON.parse(newValueStorage) : {};
      this.userService.getUserInfo(newProfile?.id).subscribe((user: any) => {
        if (user?.id !== newProfile.id) {
          window.location.reload();
        }
      });
    }
  }
}
