import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, last, map, retry } from 'rxjs';
import {
  MediaComment,
  MessageRanges,
  UserInfo,
} from '@soctrip-common/comment/lib/models/comment.models';
import { AdventureStyleRelatedTourDto } from '@assistant/angular-tour-builder-service';
import { CurrentLocationService } from './current-location.service';
import { environment } from '@env/environment';

export interface postComment {
  object_id: string;
  object_type: string;
  parent_id: string;
  content: string;
  message_ranges: MessageRanges[];
  medias: MediaComment[];
  created_by: UserInfo;
}
export interface postTranslate {
  q: string;
  source: string;
  target: string;
  format: string;
  limited: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class GetApiService {
  public styleData = new BehaviorSubject<AdventureStyleRelatedTourDto[] | undefined>([]);
  public commentChildata = new BehaviorSubject<any>(undefined);
  apiUrl: string = `${environment.BASE_API_URL}/travel-assistant-searching`;
  bookingPropertyDataApi: string = `${environment.BASE_API_URL}/booking-property-data`;
  friendApi: string = `${environment.BASE_API_URL}/friend`;
  commentApi: string = `${environment.BASE_API_URL}/comment`;
  reactionApi: string = `${environment.BASE_API_URL}/reactions`;
  promotionFilterApi: string = `${environment.BASE_API_URL}/tour-ota/promotions`;
  tourGroupApi: string = `${environment.BASE_API_URL}/tour-ota/tour-groups`;
  carRentalApi: string = `${environment.BASE_API_URL}/car-rental/vehicles`;
  tourApi: string = `${environment.BASE_API_URL}/tour-builder`;
  ratingApi: string = `${environment.BASE_API_URL}/rating`;
  chatApi: string = `${environment.BASE_API_URL}/chat`;
  carSearchV2Api: string = `${environment.BASE_API_URL}/car-rental/`;
  translateApi: string = `${environment.BASE_API_URL}/translation/translate/bulk`;
  constructor(
    private httpClient: HttpClient,
    private currentLocationService: CurrentLocationService
  ) {}

  createHeader() {
    const header = new HttpHeaders({
      'content-type': 'application/json',
    });
    return header;
  }

  translate(data: postTranslate[]): Observable<any> {
    const options = { headers: this.createHeader() };
    return this.httpClient.post<any>(this.translateApi, data, options).pipe(
      retry(1),
      map((res) => {
        return res;
      }),
      catchError((error) => {
        throw error.error;
      })
    );
  }

  searchV3(
    filter: string,
    page: string = '0',
    size: string = '10',
    sortBy: string = 'tour_type,ota_type|int,-rating|float,price|double',
    lang: string = 'en'
  ): Observable<any> {
    const param = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    const options = { headers: this.createHeader() };
    const searchV3Api = `${this.apiUrl}/tour/search/v3`;
    const currentLocation = this.currentLocationService.getCurrentCountryCode();

    this.currentLocationService
      .getTargetSearchLocation()
      .pipe(last())
      .subscribe({
        next: (data) => {
          if (currentLocation === data) {
            sortBy = 'tour_type,-ota_type|int,-rating|float,price|double';
          }
        },
      });

    return this.httpClient.get(
      searchV3Api +
        '?filter=' +
        encodeURIComponent(filter) +
        '&page=' +
        page +
        '&size=' +
        size +
        '&sortBy=' +
        encodeURIComponent(sortBy) +
        '&lang=' +
        lang,
      options
    );
  }

  searchV4(
    filter: string,
    page: string = '0',
    size: string = '10',
    sortBy: string = 'tour_type.keyword,ota_type|int,-rating|float,price|double',
    lang: string = 'en'
  ): Observable<any> {
    const param = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    const options = { headers: this.createHeader() };
    const searchV4Api = `${this.apiUrl}/tour/search/v4`;
    const currentLocation = this.currentLocationService.getCurrentCountryCode();

    this.currentLocationService
      .getTargetSearchLocation()
      .pipe(last())
      .subscribe({
        next: (data) => {
          if (currentLocation === data) {
            sortBy = 'tour_type.keyword,ota_type|int,-rating|float,price|double';
          }
        },
      });

    return this.httpClient.get(
      searchV4Api +
        '?filter=' +
        encodeURIComponent(filter) +
        '&page=' +
        page +
        '&size=' +
        size +
        '&sortBy=' +
        encodeURIComponent(sortBy) +
        '&lang=' +
        lang,
      options
    );
  }
  searchTourTrending(
    filter: string,
    page: string = '0',
    size: string = '10',
    sortBy: string = ' tour_type,ota_type|int,-rating|float,price|double',
    lang: string = 'en'
  ): Observable<any> {
    const param = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    const options = { headers: this.createHeader() };
    const searchV4Api = `${this.apiUrl}/home-search/tours/trending`;
    const currentLocation = this.currentLocationService.getCurrentCountryCode();

    this.currentLocationService
      .getTargetSearchLocation()
      .pipe(last())
      .subscribe({
        next: (data) => {
          if (currentLocation === data) {
            sortBy = 'tour_type,-ota_type|int,-rating|float,price|double';
          }
        },
      });

    return this.httpClient.get(
      searchV4Api +
        '?filter=' +
        encodeURIComponent(filter) +
        '&page=' +
        page +
        '&size=' +
        size +
        '&sort_by=' +
        encodeURIComponent(sortBy) +
        '&lang=' +
        lang,
      options
    );
  }

  filterPromotion(
    id: string,
    filter: string,
    status: string,
    pageNum: number,
    pageSize: number
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const filterPromotion = `${this.promotionFilterApi}`;
    return this.httpClient.get(
      filterPromotion +
        '?groupId=' +
        id +
        '&filter=' +
        encodeURIComponent(filter) +
        '&status=' +
        status +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize,
      options
    );
  }

  getCountStatusPromotion(id: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const countStatus = `${this.promotionFilterApi}`;
    return this.httpClient.get(countStatus + '/count/status?groupId=' + id, options);
  }

  searchTourGroup(
    agency_id: string,
    filter: string,
    pageNum: number,
    pageSize: number,
    sortBy: string = '-updated_at|LocalDateTime'
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const searchTourGroup = `${this.tourGroupApi}`;
    return this.httpClient.get(
      searchTourGroup +
        '/search?agency_id=' +
        agency_id +
        '&filters=' +
        encodeURIComponent(filter) +
        '&page=' +
        pageNum +
        '&size=' +
        pageSize +
        '&sort_by=' +
        encodeURIComponent(sortBy),
      options
    );
  }

  bookingPropertyData(pageNum: number, pageSize: number, keyWord: string): Observable<any> {
    const param = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    const options = { headers: this.createHeader() };
    const bookingProperty = `${this.bookingPropertyDataApi}/property/search-name`;
    return this.httpClient.get(
      bookingProperty + '?page_num=' + pageNum + '&page_size=' + pageSize + '&keyword=' + keyWord,
      options
    );
  }
  getLocationByLatLong(lat: number, long: number, category?: string) {
    const options = { headers: this.createHeader() };
    const api = `${environment.BASE_API_URL}/map-location/place/retrieve-place`;
    return this.httpClient.get(api + '?lat=' + lat + '&lon=' + long);
  }

  getLatLongByLocation(placeId: string) {
    const options = { headers: this.createHeader() };
    const api = `${environment.BASE_API_URL}/map-location/place/getplaceinfo`;
    return this.httpClient.get(api + '?place_id=' + placeId);
  }

  getMatchingRestaurantData(pageNum: number, pageSize: number, filters: string): Observable<any> {
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    const options = { headers: this.createHeader() };
    const restaurantApiUrl = `${environment.BASE_API_URL}/restaurant/restaurants/search`;
    const sortBy = '-rating|double';
    return this.httpClient.get(
      restaurantApiUrl +
        '?pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        `${
          filters
            ? '&filters=' + encodeURIComponent(filters)
            : '&sortBy=' + encodeURIComponent(sortBy)
        }`,
      options
    );
  }

  getRestaurantNearYou(
    pageNum: number,
    pageSize: number,
    lat: number,
    long: number
  ): Observable<any> {
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    const options = { headers: this.createHeader() };
    const restaurantApiUrl = `${environment.BASE_API_URL}/restaurant/restaurants/near-you`;
    return this.httpClient.get(
      restaurantApiUrl +
        '?pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        `&latitude=${lat}&longitude=${long}`,
      options
    );
  }
  getHotelByCoordinates(
    pageNum: number,
    pageSize: number,
    lat: number,
    long: number,
    checkin: string,
    checkout: string,
    num_of_rooms: number,
    num_of_children: number,
    num_of_adults: number,
    currency: string = 'USD'
  ): Observable<any> {
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    const options = { headers: this.createHeader() };
    const restaurantApiUrl = `${environment.BASE_API_URL}/booking-property-search/property/search-by-coordinates`;
    return this.httpClient.post(
      restaurantApiUrl +
        `?page_num=${pageNum}` +
        `&page_size=${pageSize}` +
        `&latitude=${lat}` +
        `&longitude=${long}` +
        `&checkin=${checkin}` +
        `&checkout=${checkout}` +
        `&num_of_rooms=${num_of_rooms}` +
        `&num_of_children=${num_of_children}` +
        `&num_of_adults=${num_of_adults}` +
        `&currency=${currency}`,
      {}
    );
  }
  getListFriend(text: string, pageNum: number): Observable<any> {
    const options = { headers: this.createHeader() };
    const suggestFriend = `${this.friendApi}/search`;
    return this.httpClient.get(
      suggestFriend + '?' + (text === '' ? '' : 'text=' + text + '&') + 'page=' + pageNum,
      options
    );
  }

  postComment(body: postComment): Observable<any> {
    const options = { headers: this.createHeader() };
    const postComment = `${this.commentApi}/comments`;
    return this.httpClient.post(postComment, body, options);
  }

  getComment(
    id: string,
    pageNum?: number,
    pageSize?: number,
    referenceType?: 'TIME_ASC' | 'TIME_DESC' | 'RELATE_TO_ME'
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const getComment = `${this.commentApi}/comments/`;
    return this.httpClient.get(
      getComment +
        id +
        '?' +
        'pageNum=' +
        pageNum +
        '&' +
        'pageSize=' +
        pageSize +
        '&' +
        'referenceType=' +
        referenceType,
      options
    );
  }
  countCommenById(id: string) {
    const options = { headers: this.createHeader() };
    const getComment = `${this.commentApi}/comments/`;
    return this.httpClient.get(getComment + '/count?objectId=' + id, options);
  }
  getCommentById(id: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const getComment = `${this.commentApi}/comments/`;
    return this.httpClient.get(getComment + id + '/object', options);
  }

  getReplyComment(
    id: string,
    pageNum: number,
    pageSize: number,
    referenceType: 'TIME_ASC' | 'TIME_DESC' | 'RELATE_TO_ME'
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const getComment = `${this.commentApi}/comments/`;
    return this.httpClient.get(
      getComment +
        id +
        '/replies?' +
        'pageNum=' +
        pageNum +
        '&' +
        'pageSize=' +
        pageSize +
        '&' +
        'referenceType=' +
        referenceType,
      options
    );
  }

  deleteComment(id: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const deleteComment = `${this.commentApi}/comments/`;
    return this.httpClient.delete(deleteComment + id, options);
  }

  getReaction(): Observable<any> {
    const options = { headers: this.createHeader() };
    const getReaction = `${this.reactionApi}/reactions/`;
    return this.httpClient.get(getReaction + 'my-reactions', options);
  }

  searchCarRental(
    startTime: any,
    endTime: any,
    pageNum: number,
    pageSize: number,
    filter: string
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const searchCarRental = `${this.carRentalApi}/`;
    return this.httpClient.get(
      searchCarRental +
        'search?' +
        'start_time=' +
        startTime +
        '&end_time=' +
        endTime +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        '&filters=' +
        encodeURIComponent(filter),
      options
    );
  }

  searchCarRentalV2(
    filters: string,
    page: string = '0',
    size: string = '10',
    sort: string = '-point|double'
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const searchV2Api = `${this.carSearchV2Api}/vehicles/search/v2`;

    return this.httpClient.get(
      searchV2Api +
        '?filters=' +
        encodeURIComponent(filters) +
        '&page_num=' +
        page +
        '&page_size=' +
        size +
        '&sort=' +
        encodeURIComponent(sort),
      options
    );
  }

  getTourById(id: string, lang: string = 'en'): Observable<any> {
    const options = { headers: this.createHeader() };
    const tourApi = `${this.tourApi}/tours/`;
    return this.httpClient.get(tourApi + id + '?' + 'lang=' + lang, options);
  }

  getReviewById(id: string) {
    const options = { headers: this.createHeader() };
    const ratingApi = `${this.ratingApi}/ratings/`;
    return this.httpClient.get(ratingApi + id + '/object');
  }

  connectChatToUser(destinationId: string) {
    const options = { headers: this.createHeader() };
    const chatGeneralApi = `${this.chatApi}/sessions/general`;
    return this.httpClient.post(chatGeneralApi, { destinationUserId: destinationId }, options);
  }

  connectChatToAgency(body: any, activeBot: boolean) {
    const options = { headers: this.createHeader() };
    const chatGeneralApi = `${this.chatApi}/sessions/support/agency?active_bot=${activeBot}`;
    return this.httpClient.post(chatGeneralApi, body, options);
  }

  getAgencyChatSessionsCount(agencyId: string, status: string, module: string) {
    const options = { headers: this.createHeader() };
    const chatGeneralApi = `${this.chatApi}/sessions/support/agency/${agencyId}/status/${status}/count?module=${module}`;
    return this.httpClient.get(chatGeneralApi, options);
  }
}
