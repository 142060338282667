import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { GalleriaModule } from 'primeng/galleria';
import { ButtonModule } from 'primeng/button';

import { MediaPlayerComponent } from '@shared/components/media-player/media-player.component';

import { ImagePathPipe } from '@shared/pipes/image-path.pipe';
import { PluralizePipe } from '@shared/pipes/pluralize.pipe';
import { IMAGE_EXTENSION, VIDEO_EXTENSION } from '@shared/utils/getMediaPath';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '@core/services';

@Component({
  selector: 'app-gallery',
  standalone: true,
  imports: [
    CommonModule,
    GalleriaModule,
    ButtonModule,
    MediaPlayerComponent,
    ImagePathPipe,
    PluralizePipe,
    /* Translate */
    TranslateModule,
  ],
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, OnChanges {
  /**
   * Images array
   */
  @Input() images: string[];
  /**
   * Show/hide add to favorite button
   */
  @Input() favoriteButton: boolean = false;
  /**
   * Duration badge
   */
  @Input() durationBadge: number;
  @Input() durationUnit: string = 'day';
  /**
   * Rating badge
   */
  @Input() ratingBadge: number;
  /**
   * Check preview mode
   */
  @Input() isPreviewMode: boolean = false;
  /**
   * Check to bookmark state to change icon
   */
  @Input() isBookmarked: boolean = false;
  /**
   * Get type of gallery
   */
  @Input() type?: 'custom-1';
  /**
   * Set container styles
   */
  @Input() containerStyle?: {};
  /**
   * Size of gallery
   */
  @Input() numVisible: number = 10;
  /**
   * Enable loop mode (default is TRUE)
   */
  @Input() loop: boolean = true;
  /**
   * Enable autoplay mode (default is TRUE)
   */
  @Input() autoPlay: boolean = true;
  /**
   * Current active item index
   */
  @Input() activeIndex: number = 0;
  /**
   * Add to favorite list handler
   */
  @Output() handleAddToFavorite = new EventEmitter();

  numberOfImages: number;

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.activeIndex = this.activeIndex || 0;
    this.numberOfImages = this.images?.length;
    if (window.innerWidth <= 768) {
      this.numVisible = 5;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['images'] &&
      changes['images'].previousValue?.length !== changes['images'].currentValue?.length
    ) {
      this.numberOfImages = changes['images'].currentValue?.length;
      this.activeIndex =
        this.activeIndex && this.activeIndex < this.numberOfImages ? this.activeIndex : 0;
    }
  }

  prev(): void {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    } else this.activeIndex = this.numberOfImages - 1;
  }

  next(): void {
    if (this.activeIndex < this.numberOfImages - 1) {
      this.activeIndex++;
    } else this.activeIndex = 0;
  }

  getTypeClass() {
    const typeClass = this.images && this.images.length < this.numVisible ? 'custom-2' : this.type;
    return 't-gallery t-gallery-' + this.getNumVisible() + (typeClass ? ` t-${typeClass}` : '');
  }

  getNumVisible() {
    return this.images && this.images.length < this.numVisible
      ? this.images.length
      : this.numVisible;
  }

  getRatingBadgeString(rating: number): string {
    return `${rating}/10`;
  }

  checkMediaType(url: string): string {
    const ext = url.split('.').pop() as string;
    if (IMAGE_EXTENSION.includes(ext)) return 'image';
    else if (VIDEO_EXTENSION.includes(ext)) return 'video';
    return 'other';
  }

  getTranslationAsync(key: string, interpolateParams?: Object): string {
    return this.translationService.getTranslationAsync(key, interpolateParams);
  }
}
